import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Switch } from '@mui/material';

import { Pagination } from '../../../../Utils';
import { Confirmation, ErrorToast, SuccessToast } from '../../../../Utils/SweetAlert';
import { GetSubscriptionRequestList, PostMarkAsFreeOrPaid } from '../../../../Api/subscriptionRequest';
import { Formik } from 'formik';

import SubscriptionRequestSearchBar from './components/SubscriptionRequestSearchBar';
import { SubscriptionRequestInitialValues } from '../../constant';

const SubscriptionRequests = () => {
  const formRef = useRef();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const {
    data: subscriptionRequest,
    isFetching: subscriptionLoading,
    refetch,
  } = useQuery({
    queryKey: ['get-subscription-requests-list', limit, page],
    queryFn: () =>
      GetSubscriptionRequestList({
        limit: limit,
        offset: page,
        search: formRef?.current?.values?.search,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  const handleConfirmRequest = (values) => {
    Confirmation(
      values?.is_active === true ? 'Want to approve user subscription?' : 'Want to cancle user subscription?',
    ).then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await PostMarkAsFreeOrPaid(values)
          .then((res) => {
            SuccessToast(res?.data?.message || 'Subscription Updated');
            refetch();
          })
          .catch((err) => {
            if (typeof err.response.data.message !== 'undefined') {
              ErrorToast(err.response.data.message || 'Server Error!!');
            } else {
              ErrorToast(err?.message || 'Server Error!!');
            }
          });
      }
    });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    refetch();
  };

  const handleLimitChange = async (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    refetch();
  };

  return (
    <>
      <Helmet>
        <title>Parato | Admin Subscription</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>Subscription Requests</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/dashboard' className='text-info text-decoration-underline'>
                      Dashboard
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>Subscription-Requests</li>
                </ul>
              </div>
              {/* <div className='col-auto'>
                <Link to='/admin/add-insight' className='btn btn-primary me-1'>
                  <i className='fas fa-plus' /> Add User
                </Link>
              </div> */}
            </div>
          </div>
          <Formik initialValues={SubscriptionRequestInitialValues} innerRef={formRef}>
            <form>
              <SubscriptionRequestSearchBar refetch={refetch} />
            </form>
          </Formik>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive custom-pagination'>
                    <table className='table table-center table<img<image-hover datatable table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          <th>Name</th>
                          <th>E-mail</th>
                          <th>Phone Number</th>
                          <th>Company Name</th>
                          <th>Subscription</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptionLoading && (
                          <tr>
                            <td align='center' colSpan={8}>
                              <div className='loader-wraper'>
                                <div className='spinner-border text-light' role='status' />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!subscriptionLoading && subscriptionRequest !== undefined && (
                          <>
                            {subscriptionRequest?.data.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.first_name + ' ' + item?.last_name || '-'}</td>
                                <td>{item?.email || '-'}</td>
                                <td>{item?.phone_no || '-'}</td>
                                <td>{item?.company_nane || '-'}</td>
                                <td>
                                  <Switch
                                    checked={item?.user_id?.is_paid}
                                    onClick={() =>
                                      handleConfirmRequest({
                                        id: item?.user_id?._id,
                                        is_active: item?.user_id?.is_paid === true ? false : true,
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                            ))}

                            {subscriptionRequest?.data?.length === 0 && (
                              <tr>
                                <td align='left' colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={subscriptionRequest?.totalRecords || 0}
                          itemsPerPage={limit}
                          page={!subscriptionRequest?.totalRecords || subscriptionRequest?.totalRecords <= 0 ? 0 : page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className='content container-fluid absolute px-2'>
                <footer className='text-center text-lg-start'>
                  <div className='text-center'>Pareto with Pareto Economics Ltd.</div>
                </footer>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionRequests;
