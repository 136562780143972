import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { Pagination } from '../../../../Utils';
import { Confirmation, ErrorToast, SuccessToast } from '../../../../Utils/SweetAlert';
import { GetDemoRequestList, PostDemoRequest } from '../../../../Api/demoRequests';
import RequestDemoDetails from '../RequestDemoDetails';
import { Switch } from '@mui/material';

import '../../style.css';
import DemoRequestSearchBar from './components/DemoRequestSearchBar';
import { Formik } from 'formik';

const DemoRequestsList = () => {
  const formRef = useRef();
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const {
    data: demoRequest,
    isFetching: demoRequestLoading,
    refetch,
  } = useQuery({
    queryKey: ['get-demo-requests-list', limit, page],
    queryFn: () =>
      GetDemoRequestList({
        limit: limit,
        offset: page,
        sortMode: 'desc',
        is_complete: formRef?.current?.values?.is_complete?.value,
        // search: formRef?.current?.values?.search
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  const handleDetailModal = () => {
    setOpen(!open);
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    refetch();
  };

  const handleLimitChange = async (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    refetch();
  };

  const handleConfirmRequest = (values) => {
    Confirmation(
      values?.is_active === true ? 'Want to approve user subscription?' : 'Want to cancle user subscription?',
    ).then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await PostDemoRequest(values)
          .then((res) => {
            SuccessToast(res?.data?.message || 'Subscription Updated');
            refetch();
          })
          .catch((err) => {
            if (typeof err.response.data.message !== 'undefined') {
              ErrorToast(err.response.data.message || 'Server Error!!');
            } else {
              ErrorToast(err?.message || 'Server Error!!');
            }
          });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Parato | Admin Demo Requests</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>Demo Requests</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/dashboard' className='text-info text-decoration-underline'>
                      Dashboard
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>Demo-Requests</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='row'></div>
          <div className='row gap-3'>
            <Formik
              initialValues={{
                is_complete: {
                  label: 'Both',
                  value: null,
                },
              }}
              innerRef={formRef}
            >
              <form>
                <DemoRequestSearchBar refetch={refetch} />
              </form>
            </Formik>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive custom-pagination'>
                    <table className='table table-center table<img<image-hover datatable table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          <th className='text-center stickey-actions-heading'>Action</th>
                          <th>Is Complete ?</th>
                          <th>User</th>
                          <th>Email</th>
                          <th>Mobile-Number</th>
                          <th>Schedule Time</th>
                          <th>Preferred Meet Service Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {demoRequestLoading && (
                          <tr>
                            <td align='center' colSpan={8}>
                              <div className='loader-wraper'>
                                <div className='spinner-border text-light' role='status' />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!demoRequestLoading && demoRequest !== undefined && (
                          <>
                            {demoRequest?.data.map((item, index) => {
                              const timeZone = item?.schedule_time;
                              const date = new Date(timeZone);
                              let meetingTime = date.toLocaleString(date);
                              return (
                                <tr key={index}>
                                  <td className='text-center stickey-actions-body'>
                                    <Tooltip
                                      title='View'
                                      className='btn btn-sm btn-white text-success me-2'
                                      onClick={() => {
                                        setDetail(item);
                                        handleDetailModal();
                                      }}
                                    >
                                      <IconButton>
                                        <MdOutlineRemoveRedEye className='text-success' />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <Switch
                                      checked={item?.is_complete}
                                      onClick={() =>
                                        handleConfirmRequest({
                                          id: item?._id,
                                          is_active: item?.is_complete === true ? false : true,
                                        })
                                      }
                                    />
                                  </td>
                                  <td>{item?.user_id?.first_name + ' ' + item?.user_id?.last_name || '-'}</td>
                                  <td>{item?.user_id?.email || '-'}</td>
                                  <td>{item?.user_id?.mobile_no || '-'}</td>
                                  <td className=''>{`${meetingTime} (${item?.timezone})` || '-'}</td>
                                  <td className='metting-text-td'>
                                    <div className='metting-text'>
                                      <img
                                        src={`/images/${item?.preferred_meet_service}.png`}
                                        className='metting-icons'
                                        alt='user.png'
                                      />
                                      &nbsp;&nbsp;&nbsp;&nbsp;{item?.preferred_meet_service || '-'}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            {demoRequest?.data?.length === 0 && (
                              <tr>
                                <td align='center' colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}

                            {open === true && <RequestDemoDetails onClose={handleDetailModal} details={detail} />}
                          </>
                        )}
                        <Pagination
                          totalItem={demoRequest?.totalRecords || 0}
                          itemsPerPage={limit}
                          page={!demoRequest?.totalRecords || demoRequest?.totalRecords <= 0 ? 0 : page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoRequestsList;
