import * as Yup from 'yup';

export const RoleInitialValues = {
  role_name: '',
  description: '',
  rights: [],
};

export const RoleValidationSchema = Yup.object().shape({
  role_name: Yup.string().required('Role name is required'),
  description: Yup.string().required('Description is required'),
});
