import Api from '../Utils/Axios';

export const GetUserListing = async ({ limit, page, is_paid, search }) => {
  const response = await Api.post(`admin/users/list`, {
    limit,
    offset: page,
    sortMode: 'desc',
    is_paid,
    search,
  });
  return response.data;
};

export const GetSingleUser = async ({ Id }) => {
  const response = await Api.get(`admin/users/${Id}`);
  return response.data;
};

export const DeleteUser = async (data) => {
  const response = await Api.delete('admin/users', { data: data });
  return response.data;
};

export const ExportUserExcel = async (filters) => {
  const response = await Api.post('/admin/users/export-excel', filters, {
    responseType: 'blob', // Important for file download
  });
  return response.data;
};
