import React from 'react';
import { Editor } from 'primereact/editor';
import { useFormikContext } from 'formik';
import './style.css';

const TextEditor = ({ field }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Editor
      value={field?.value}
      onTextChange={(e) => {
        setFieldValue(field?.name, e.htmlValue);
      }}
      style={{ height: '200px' }}
    />
  );
};

export default TextEditor;
