import React, { useContext, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Pagination } from '../../../../Utils';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { GetInsightList, PostIsActive } from '../../../../Api/insight';
import { ErrorToast } from '../../../../Utils/SweetAlert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AppContext } from '../../../../context/appContext';
import { Confirmation, SuccessToast } from '../../../../Utils/SweetAlert';
import { useGetYearsHook } from '../../../../Hooks/useGeneralHook';
import { useMutateDeleteInsight } from '../../hooks/useApiHook';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import ViewInsight from '../InsightsList/Components/ViewInsight';
import '../../style.css';
import { Formik } from 'formik';
import { InsightSearchBarInitialValues } from '../../constant';
import InsightTableSearchBar from './Components/InsightTableSearchBar';
import { Switch } from '@mui/material';

const InsightsList = () => {
  const navigate = useNavigate();
  const formRef = useRef();

  const { years, services, countries } = useContext(AppContext);
  const yearOption = useGetYearsHook(years?.data?.min_year, years?.data?.max_year);
  const deleteInsight = useMutateDeleteInsight();

  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const handleDetailModal = () => {
    setOpen(!open);
  };

  const {
    isFetching: isInsightLoading,
    data: insightList,
    refetch,
  } = useQuery({
    queryKey: ['get-insight-list'],
    queryFn: () =>
      GetInsightList({
        limit: limit,
        offset: page,
        search: formRef?.current?.values?.search,
        sortMode: 'desc',
        sortBy: 'createdAt',
        country_id: formRef?.current?.values?.country_id?.value || null,
        type_id: formRef?.current?.values?.type_id?.value || null,
        year: formRef?.current?.values?.year?.value,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    refetch();
  };

  const handleLimitChange = async (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    refetch();
  };

  const handleDeleteRole = (insightId) => {
    const reqData = { id: insightId };

    Confirmation('Want to delete insight?').then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        deleteInsight.mutate(reqData, {
          onSuccess: (response) => {
            SuccessToast(response?.message || 'Delete successful.');
            refetch();
          },
          onError: (error) => {
            ErrorToast(error?.response?.data?.message || 'Server error.');
            console.log('error', error);
          },
        });
      }
    });
  };

  const handleConfirmRequest = (values) => {
    Confirmation(values?.is_active === true ? 'Want to  active insights?' : 'Want to disactive insights?').then(
      async (deleteConfirm) => {
        if (deleteConfirm.isConfirmed) {
          await PostIsActive(values)
            .then((res) => {
              SuccessToast(res?.data?.message || 'Subscription Updated');
              refetch();
            })
            .catch((err) => {
              if (typeof err.response.data.message !== 'undefined') {
                ErrorToast(err.response.data.message || 'Server Error!!');
              } else {
                ErrorToast(err?.message || 'Server Error!!');
              }
            });
        }
      },
    );
  };

  return (
    <>
      <Helmet>
        <title>Parato | Admin Insights</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>Insights</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/dashboard' className='text-info text-decoration-underline'>
                      Dashboard
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>Insights</li>
                </ul>
              </div>
              <div className='col-auto'>
                <Link to='/admin/add-insight' className='btn btn-them-gray me-1'>
                  <i className='fas fa-plus' /> Add Insight
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <Formik initialValues={InsightSearchBarInitialValues} innerRef={formRef} enableReinitialize>
              {() => {
                return (
                  <form>
                    <InsightTableSearchBar refetch={refetch} />
                  </form>
                );
              }}
            </Formik>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive custom-pagination'>
                    <table className='table table-center table<img<image-hover datatable table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          <th className='text-left insight-stickey-actions-heading'>Action</th>
                          <th>Is Active ?</th>
                          <th>Title</th>
                          <th>Service Type</th>
                          <th>Year</th>
                          <th>Country</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isInsightLoading && (
                          <tr>
                            <td align='center' colSpan={8}>
                              <div className='loader-wraper'>
                                <div className='spinner-border text-light' role='status' />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isInsightLoading && insightList !== undefined && (
                          <>
                            {insightList?.data.map((item, index) => {
                              const insightYera = yearOption?.find((year) => year?.value === item?.year);
                              const insightServiceType = services?.find((service) => service?._id === item?.type_id);
                              const insightCountry = countries?.find((country) => country?.value === item?.country_id);
                              var strippedHtml = item?.description.replace(/<[^>]+>/g, '');

                              return (
                                <tr key={index} className=''>
                                  <td className='text-center insight-stickey-actions-body'>
                                    <Tooltip
                                      title='Edit'
                                      className='btn btn-sm btn-white text-secondary insight-stickey-actions-btn'
                                      onClick={() =>
                                        navigate('/admin/edit-insight', {
                                          state: { id: item?._id },
                                        })
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon className='text-secondary' />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      title='Delete'
                                      className='btn btn-sm btn-white text-danger insight-stickey-actions-btn'
                                      onClick={() => handleDeleteRole(item?._id)}
                                    >
                                      <IconButton>
                                        <DeleteIcon className='text-danger' />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title='View'
                                      className='btn btn-sm btn-white text-success insight-stickey-actions-btn'
                                      onClick={() => {
                                        setDetail(item);
                                        handleDetailModal();
                                      }}
                                    >
                                      <IconButton>
                                        <MdOutlineRemoveRedEye className='text-success' />
                                      </IconButton>
                                    </Tooltip>
                                    {open === true && <ViewInsight onClose={handleDetailModal} details={detail} />}
                                  </td>
                                  <td>
                                    <Switch
                                      checked={item?.is_active}
                                      onClick={() =>
                                        handleConfirmRequest({
                                          id: item?.id,
                                          is_active: item?.is_active === true ? false : true,
                                        })
                                      }
                                    />
                                  </td>
                                  <td className=''>
                                    {item?.title !== undefined
                                      ? item?.title.length >= 35
                                        ? item?.title.substring(0, 32) + '...'
                                        : item?.title || '-'
                                      : '-'}
                                  </td>
                                  <td className=''>{insightServiceType?.label || '-'}</td>
                                  <td className=''>{insightYera?.label || '-'}</td>
                                  <td className=''>{insightCountry?.label || '-'}</td>
                                  <td className=''>
                                    {strippedHtml !== undefined
                                      ? strippedHtml.length >= 25
                                        ? strippedHtml.substring(0, 32) + '...'
                                        : strippedHtml || '-'
                                      : '-'}
                                  </td>
                                </tr>
                              );
                            })}
                            {insightList?.data?.length === 0 && (
                              <tr>
                                <td align='left' colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                        <Pagination
                          totalItem={insightList?.totalRecords || 0}
                          itemsPerPage={limit}
                          page={!insightList?.totalRecords || insightList?.totalRecords <= 0 ? 0 : page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className='content container-fluid absolute px-2'>
                <footer className='text-center text-lg-start'>
                  <div className='text-center pb-4'>Pareto with Pareto Economics Ltd.</div>
                </footer>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightsList;
