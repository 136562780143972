import * as Yup from 'yup';

export const InsightInitialValues = {
  title: '',
  description: '',
  year: null,
  country_id: '',
  type_id: '',
  is_paid: false,
  is_active: false,
};

export const InsightValidationSchema = Yup.object()
  .shape({
    title: Yup.string().required('Please enter title'),
    description: Yup.string().required('Please enter description'),
    year: Yup.object().required('Please select year'),
    country_id: Yup.object().required('Please select country'),
    type_id: Yup.object().required('Please select index'),
    // is_paid: Yup.bool().oneOf([true], 'Please'),
  })
  .defined();

export const InsightSearchBarInitialValues = {
  search: '',
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};
