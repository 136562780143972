/* eslint-disable no-unused-vars */
import { Field, useFormikContext } from 'formik';
import React, { useContext, useEffect } from 'react';
import SelectBox from '../../../../../../Components/SelectBox';
import { AppContext } from '../../../../../../context/appContext';
import { useGetYearsHook } from '../../../../../../Hooks/useGeneralHook';

const InsightTableSearchBar = ({ refetch }) => {
  const { values, setFieldValue } = useFormikContext();
  const { isYearLoading, years, isServicesLoading, services, isCountriesLoading, countries } = useContext(AppContext);
  const yearOption = useGetYearsHook(years?.data?.min_year, years?.data?.max_year);

  useEffect(() => {
    if (values) {
      refetch();
    }
  }, [refetch, values]);

  return (
    <div className='row mb-3 align-item-right'>
      <div className='col-md-3 col-xl-3 col-lg-3'>
        <label className='text-white d-block'>Year</label>
        <Field
          name='year'
          placeholder='Select year'
          component={SelectBox}
          options={yearOption}
          isLoading={isYearLoading}
        />
      </div>
      <div className='col-md-3 col-xl-3 col-lg-3'>
        <label className='text-white d-block'>Index</label>
        <Field
          name='type_id'
          placeholder='Select index'
          component={SelectBox}
          options={services}
          isLoading={isServicesLoading}
          isClearable={true}
        />
      </div>
      <div className='col-md-3 col-xl-3 col-lg-3'>
        <label className='text-white d-block'>Country</label>
        <Field
          name='country_id'
          placeholder='Select country'
          component={SelectBox}
          options={countries}
          isLoading={isCountriesLoading}
          isClearable={true}
        />
      </div>
      <div className='col-md-3 col-xl-3 col-lg-3'>
        <label className='text-white d-block'>Search</label>
        <input
          className='input-customize'
          type='text'
          name='search'
          value={values?.search}
          onChange={(e) => {
            const query = e.target.value;
            setFieldValue('search', query);
          }}
          placeholder='search here....'
        />
      </div>
    </div>
  );
};

export default InsightTableSearchBar;
