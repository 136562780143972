import React, { useContext } from 'react';
import DetailModal from '../../../../../../Components/DetailModal';
import { AppContext } from '../../../../../../context/appContext';

const ViewInsight = ({ onClose, details }) => {
  const { services, countries } = useContext(AppContext);

  const insightServiceType = services?.find((service) => service?._id === details?.type_id);
  const insightCountry = countries?.find((country) => country?.value === details?.country_id);

  return (
    <DetailModal centered show size='lg' onClose={onClose} title='Insight Details'>
      <div className='row'>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Title</label>
          <span className='d-block modal-value'>{details?.title || '-'}</span>
        </div>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Year</label>
          <span className='d-block modal-value'>{details?.year || '-'}</span>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>service</label>
          <span className='d-block modal-value'>{insightServiceType?.label || '-'}</span>
        </div>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Country</label>
          <span className='d-block modal-value'>{insightCountry?.label || '-'}</span>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-12 col-xl-12 col-lg-12 d-block'>
          <label className='d-block modal-label'>Description</label>
          <span className='d-block modal-value description-box-ui'>
            <div className='post__content' dangerouslySetInnerHTML={{ __html: details?.description }}></div>
          </span>
        </div>
      </div>
    </DetailModal>
  );
};

export default ViewInsight;
