import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { InsightInitialValues, InsightValidationSchema } from '../../constant';
import { AppContext } from '../../../../context/appContext';
import { useGetYearsHook } from '../../../../Hooks/useGeneralHook';
import { useMutateEditInsight } from '../../hooks/useApiHook';
import { ErrorToast, SuccessToast } from '../../../../Utils/SweetAlert';
import { GetSingleInsight } from '../../../../Api/insight';
import { useQuery } from '@tanstack/react-query';
import { Switch } from '@mui/material';
import TextEditor from '../../../../Components/TextEditor';
import InputWithLabel from '../../../../Components/InputWithLabel';
import SelectBox from '../../../../Components/SelectBox';

const EditInsight = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const stateData = useLocation();
  const { id } = stateData?.state || '';

  const { isYearLoading, years, isServicesLoading, services, isCountriesLoading, countries } = useContext(AppContext);
  const yearOption = useGetYearsHook(years?.data?.min_year, years?.data?.max_year);
  const editInsightApi = useMutateEditInsight();

  const { data } = useQuery({
    queryKey: ['get-single-insight'],
    queryFn: () => GetSingleInsight({ id: id }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  useEffect(() => {
    if (formRef?.current?.values) {
      formRef?.current?.setValues({
        title: data?.data?.title,
        description: data?.data?.description,
        year: { label: data?.data?.year, value: data?.data?.year },
        country_id: {
          label: data?.data?.country_id?.name,
          value: data?.data?.country_id?._id,
          ...data?.data?.country_id,
        },
        type_id: { label: data?.data?.type_id?.name, value: data?.data?.type_id?._id, ...data?.data?.type_id },
        is_paid: data?.data?.is_paid,
        is_active: data?.data?.is_active,
      });
    }
  }, [data]);

  const handleSubmitEditInsight = (values) => {
    const reqData = {
      id: id,
      title: values?.title,
      description: values?.description,
      year: values?.year?.value,
      country_id: values?.country_id?.value,
      type_id: values?.type_id?.value,
      is_paid: values?.is_paid,
      is_active: values?.is_active,
    };

    editInsightApi.mutate(reqData, {
      onSuccess: (response) => {
        SuccessToast(response?.message || ' Insight updated.');
        navigate('/admin/insight');
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || 'Server error.');
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>Parato | Admin Edit Insight</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row'>
              <div className='col'>
                <h3 className='page-title'>Edit Insight</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/dashboard' className='text-info text-decoration-underline'>
                      Dashboard
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/insight' className='text-info text-decoration-underline'>
                      Insights
                    </Link>
                  </li>
                  <li className='breadcrumb-item active'>Edit Insight</li>
                </ul>
              </div>
              <div className='col-auto'>
                <Link to='/admin/insight' className='btn btn-them-gray me-1'>
                  <i className='fas fa-angle-double-left' /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'>
                  <h5 className='card-title'>Insight Details</h5>
                </div>
                <div className='card-body'>
                  <Formik
                    initialValues={InsightInitialValues}
                    validationSchema={InsightValidationSchema}
                    validateOnChange
                    onSubmit={handleSubmitEditInsight}
                    innerRef={formRef}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting, setFieldValue, values } = formik;
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-6'>
                              <Field
                                Label='Title'
                                type='text'
                                name='title'
                                placeholder='Enter title'
                                component={InputWithLabel}
                              />
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label className='text-white'>
                                  Index <span className='text-danger'>*</span>
                                </label>
                                <Field
                                  name='type_id'
                                  placeholder='Select index'
                                  component={SelectBox}
                                  options={services}
                                  isLoading={isServicesLoading}
                                />
                                <ErrorMessage name='type_id' component='span' className='error' />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label className='text-white'>
                                  Year <span className='text-danger'>*</span>
                                </label>
                                <Field
                                  name='year'
                                  placeholder='Select year'
                                  component={SelectBox}
                                  options={yearOption}
                                  isLoading={isYearLoading}
                                />
                                <ErrorMessage name='year' component='span' className='error' />
                              </div>
                            </div>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label className='text-white'>
                                  Country <span className='text-danger'>*</span>
                                </label>
                                <Field
                                  name='country_id'
                                  placeholder='Select country'
                                  component={SelectBox}
                                  options={countries}
                                  isLoading={isCountriesLoading}
                                />
                                <ErrorMessage name='country_id' component='span' className='error' />
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='form-group'>
                                <label className='text-white'>
                                  Description <span className='text-danger'>*</span>
                                </label>
                                <Field
                                  className={`form-control ${
                                    errors.description && touched.description ? 'input-error' : null
                                  }`}
                                  name='description'
                                  component={TextEditor}
                                />
                                <ErrorMessage name='description' component='span' className='error' />
                              </div>
                              <div className='row'>
                                <div className='form-group col-md-2'>
                                  <label className='d-block text-white'>Is Paid</label>
                                  <div>
                                    <Switch
                                      checked={values?.is_paid === true}
                                      name='is_paid'
                                      onClick={(e) => {
                                        setFieldValue('is_paid', e.target.checked);
                                      }}
                                    />
                                  </div>
                                  <ErrorMessage name='is_paid' component='span' className='error d-block' />
                                </div>
                                <div className='form-group col-md-2'>
                                  <label className='d-block text-white'>Is Active</label>
                                  <div>
                                    <Switch
                                      checked={values?.is_active === true}
                                      name='is_active'
                                      onClick={(e) => {
                                        setFieldValue('is_active', e.target.checked);
                                      }}
                                    />
                                  </div>
                                  <ErrorMessage name='is_active' component='span' className='error d-block' />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='text-end mt-4'>
                            <button type='submit' className='btn btn-them-gray btnstl' disabled={isSubmitting}>
                              Edit Insight
                              {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            {/* <div className='container-fluid absolute mb-4'>
              <footer className='text-center text-lg-start'>
                <div className='text-center'>Pareto with Pareto Economics Ltd.</div>
              </footer>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInsight;
