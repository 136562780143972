import * as Yup from 'yup';
const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export const UserInitialValues = {
  name: '',
  contact_number: '',
  email: '',
  password: '',
  confirm_password: '',
  role: '',
  // website: "",
};

export const UserValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required'),
  name: Yup.string().required('Name is required'),
  // website: Yup.string()
  //   .matches(
  //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //     "Enter correct url!"
  //   )
  //   .required("Please enter website"),
  contact_number: Yup.string()
    .required('Contact number is required')
    .matches(phoneRegExp, 'Contact number is not valid'),
  role: Yup.string().required('Please select role'),
  password: Yup.string()
    .required('Password is required')
    .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
    .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
    .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
    .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});
