import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Switch } from '@mui/material';

import '../../style.css';
import { Pagination } from '../../../../Utils';
import { GetUserListing } from '../../../../Api/user';
import { Confirmation, ErrorToast, SuccessToast } from '../../../../Utils/SweetAlert';
import { useGetCountriesHook, useGetRegionsHook, useGetServicesHook } from '../../../../Hooks/useGeneralHook';
import { Formik } from 'formik';
import UserTableSearchBar from './components/UserTableSearchBar';
import { userSearchBarInitialValues } from '../../constant';
import { PostMarkAsActiveInactive, PostMarkAsFreeOrPaid } from '../../../../Api/subscriptionRequest';
import { useMutateDeleteUser } from '../hooks/useApiHooks';
import { ExportUserExcel } from '../../../../Api/user';

const UserListing = () => {
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const navigate = useNavigate();
  const deleteUser = useMutateDeleteUser();

  const { data: services } = useGetServicesHook();

  const { data: countries } = useGetCountriesHook();

  const { data: regions } = useGetRegionsHook();

  const {
    isFetching: userDataLoading,
    data: userData,
    refetch,
  } = useQuery({
    queryKey: ['get-user-list', limit, page],
    queryFn: () =>
      GetUserListing({
        limit: limit,
        page: page,
        is_paid: formRef?.current?.values?.is_paid?.value === null ? '' : formRef?.current?.values?.is_paid?.value,
        search: formRef?.current?.values?.search,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    refetch();
  };

  const handleLimitChange = async (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    refetch();
  };

  const handleConfirmRequest = (values) => {
    Confirmation(
      values?.is_active === true ? 'Want to approve user subscription?' : 'Want to cancle user subscription?',
    ).then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await PostMarkAsFreeOrPaid(values)
          .then((res) => {
            SuccessToast(res?.data?.message || 'Subscription Updated');
            refetch();
          })
          .catch((err) => {
            if (typeof err.response.data.message !== 'undefined') {
              ErrorToast(err.response.data.message || 'Server Error!!');
            } else {
              ErrorToast(err?.message || 'Server Error!!');
            }
          });
      }
    });
  };

  const handleActiveConfirmRequest = (values) => {
    Confirmation(values?.is_active === true ? 'Want to active user account?' : 'Want to inactive user account?').then(
      async (deleteConfirm) => {
        if (deleteConfirm.isConfirmed) {
          await PostMarkAsActiveInactive(values)
            .then((res) => {
              SuccessToast(res?.data?.message || 'Account Updated');
              refetch();
            })
            .catch((err) => {
              if (typeof err.response.data.message !== 'undefined') {
                ErrorToast(err.response.data.message || 'Server Error!!');
              } else {
                ErrorToast(err?.message || 'Server Error!!');
              }
            });
        }
      },
    );
  };

  const handleDeleteRole = (userId) => {
    const reqData = { id: userId };

    Confirmation('Want to delete user??').then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        deleteUser.mutate(reqData, {
          onSuccess: (response) => {
            SuccessToast(response?.message || 'Delete successful.');
            refetch();
          },
          onError: (error) => {
            ErrorToast(error?.response?.data?.message || 'Server error.');
            console.log('error', error);
          },
        });
      }
    });
  };

  const handleExportExcel = async () => {
    const filters = {
      limit,
      offset: page,
      is_paid: formRef?.current?.values?.is_paid?.value || '',
      search: formRef?.current?.values?.search,
    };

    setLoading(true); // Set loading to true

    try {
      const excelData = await ExportUserExcel(filters);
      const url = window.URL.createObjectURL(new Blob([excelData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'user_listing.xlsx'); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      ErrorToast('Failed to export Excel file.');
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <Helmet>
        <title>Parato | Admin User</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>User Listing</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item '>
                    <Link to='/admin/dashboard' className='text-info text-decoration-underline'>
                      Dashboard
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>User Listing</li>
                </ul>
              </div>
            </div>
          </div>
          <Formik initialValues={userSearchBarInitialValues} innerRef={formRef}>
            {() => {
              return (
                <form>
                  <UserTableSearchBar refetch={refetch} handleExportExcel={handleExportExcel} loading={loading} />
                </form>
              );
            }}
          </Formik>
          <div className='col-sm-12'>
            <div className='card card-table'>
              <div className='card-body'>
                <div className='table-responsive custom-pagination'>
                  <table className='table table-center table<img<image-hover datatable table-manager'>
                    <thead className='thead-light'>
                      <tr>
                        <th className='user-stickey-actions-heading'>Action</th>
                        <th>Subscription</th>
                        <th>Activation</th>
                        <th>Is Paid ?</th>
                        <th>Name</th>
                        <th>E-mail</th>
                        <th>Phone Number</th>
                        <th>Company Name</th>
                        <th>Job Title</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userDataLoading && (
                        <tr>
                          <td align='center' colSpan={8}>
                            <div className='loader-wraper'>
                              <div className='spinner-border text-light' role='status' />
                            </div>
                          </td>
                        </tr>
                      )}
                      {!userDataLoading && userData !== undefined && (
                        <>
                          {userData?.data.map((item, index) => {
                            let userServices = [];
                            let userRegion = [];
                            let userCountries = [];
                            if (services) {
                              services?.map((itemTwo) => {
                                if (item?.interested_indices.includes(itemTwo?._id) === true) {
                                  userServices?.push(itemTwo?.label);
                                }
                              });
                            }
                            if (regions) {
                              regions?.map((itemTwo) => {
                                if (item?.interested_region.includes(itemTwo?.value) === true) {
                                  userRegion?.push(itemTwo?.label);
                                }
                              });
                            }
                            if (countries) {
                              countries?.map((itemTwo) => {
                                if (item?.interested_countries.includes(itemTwo?.value) === true) {
                                  userCountries?.push(itemTwo?.label);
                                }
                              });
                            }
                            return (
                              <tr key={index} className='table'>
                                <td className='user-stickey-actions-body'>
                                  <Tooltip
                                    title='View'
                                    className='btn btn-sm btn-white text-success'
                                    onClick={() => {
                                      navigate('/admin/view-user', {
                                        state: { id: item?.id },
                                      });
                                    }}
                                  >
                                    <IconButton>
                                      <MdOutlineRemoveRedEye size='40' className='text-success' />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title='Delete'
                                    className='btn btn-white text-danger'
                                    onClick={() => handleDeleteRole(item?._id)}
                                  >
                                    <IconButton>
                                      <DeleteIcon className='text-danger delete-icon-style' size={25} />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                                <td>
                                  <Switch
                                    style={{ zIndex: 10 }}
                                    checked={item?.is_paid}
                                    onClick={() =>
                                      handleConfirmRequest({
                                        id: item?.id,
                                        is_active: item?.is_paid === true ? false : true,
                                      })
                                    }
                                  />
                                </td>
                                <td>
                                  <Switch
                                    checked={item?.status}
                                    onClick={() =>
                                      handleActiveConfirmRequest({
                                        id: item?.id,
                                        is_active: item?.status === 1 ? false : true,
                                        status: item?.status === 1 ? 0 : 1,
                                      })
                                    }
                                  />
                                </td>
                                <td className='user-td-padding-remove'>
                                  {item?.is_paid === true ? (
                                    <span className='badge badge-success'>Paid User</span>
                                  ) : (
                                    <span className='badge badge-danger'>Free User</span>
                                  )}
                                </td>
                                <td className='user-td-padding-remove'>
                                  {item?.first_name + ' ' + item?.last_name || '-'}
                                </td>
                                <td className='user-td-padding-remove'>{item?.email || '-'}</td>
                                <td className='user-td-padding-remove'>{item?.mobile_no || '-'}</td>
                                <td>{item?.company_name || '-'}</td>
                                <td>{item?.job_title || '-'}</td>
                              </tr>
                            );
                          })}
                          {userData?.data?.length === 0 && (
                            <tr>
                              <td align='left' colSpan={9}>
                                No Records Found.
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                      <Pagination
                        totalItem={userData?.totalRecords || 0}
                        itemsPerPage={limit}
                        page={!userData?.totalRecords || userData?.totalRecords <= 0 ? 0 : page}
                        handleChangePage={handlePageChange}
                        handleChangeRowsPerPage={handleLimitChange}
                        colSpan={9}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div className='content container-fluid absolute px-2'>
                <footer className='text-center text-lg-start'>
                  <div className='text-center pb-4'>Pareto with Pareto Economics Ltd.</div>
                </footer>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserListing;
