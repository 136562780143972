import Api from '../Utils/Axios';

export const GetInsightList = async (data) => {
  const response = await Api.post('admin/insights/list', data);
  return response.data;
};

export const CreateInsight = async (data) => {
  const response = await Api.post('admin/insights', data);
  return response.data;
};

export const UpdateInsight = async (data) => {
  const response = await Api.put('admin/insights', data);
  return response.data;
};

export const DeleteInsight = async (data) => {
  const response = await Api.delete('admin/insights', { data: data });
  return response.data;
};

export const GetSingleInsight = async ({ id }) => {
  const response = await Api.get(`admin/insights/${id}`);
  return response.data;
};

export const PostIsActive = async (data) => {
  const response = await Api.post('admin/insights/status', data);
  return response.data;
};
