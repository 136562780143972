import Api from '../Utils/Axios';

export const GetDemoRequestList = async (data) => {
  const response = await Api.post(`admin/book-demo/list`, data);
  return response.data;
};

export const PostDemoRequest = async (data) => {
  const response = await Api.post('admin/book-demo/status', data);
  return response.data;
};
