import React, { useState } from 'react';
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar';
import * as Yup from 'yup';
// import { AdminHeader, AdminSidebar } from '../../Layout/'
import { Helmet } from 'react-helmet-async';
import { ErrorToast, SuccessToast } from '../../../Utils/SweetAlert';
import { ChangePasswordService } from '../../../Services/Api/auth';

const ChangePassword = () => {
  const [isShowPasswordCurrent, setIsShowPasswordCurrent] = useState(false);
  const [isShowPasswordNew, setIsShowPasswordNew] = useState(false);
  const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState(false);

  const signInSchema = Yup.object().shape({
    newPass: Yup.string()
      .min(8, 'Minimum 8 character required')
      .required('New password is required')
      .test('uppderCase', 'Must contain at least one uppercase character', (value) => /[A-Z]/.test(value))
      .test('lowerCase', 'Must contain at least one lowercase character', (value) => /[a-z]/.test(value))
      .test('oneNumber', 'Must contain at least one number', (value) => /[0-9]/.test(value))
      .test('oneSpecial', 'Must contain at least one special character', (value) => /[!@#%&]/.test(value)),
    conformNewPass: Yup.string()
      .oneOf([Yup.ref('newPass'), null], 'Passwords must match')
      .required('Confirm password is required'),
    currentPass: Yup.string().required('Current password is required'),
  });

  const initialValues = {
    newPass: '',
    conformNewPass: '',
    currentPass: '',
  };

  return (
    <>
      <Helmet>
        <title>Parato | Admin Change Password</title>
      </Helmet>
      <div>
        {/* <div className="main-wrapper">
               <div className="header header-one">
                  <AdminHeader />
               </div>
               <div className="sidebar" id="sidebar">
                  <AdminSidebar />
               </div> */}
        <div className='page-wrapper'>
          <div className='content container-fluid'>
            <div className='page-header'>
              <div className='row'>
                <div className='col-sm-6'>
                  <h3 className='page-title'>Settings</h3>
                  <ul className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <Link to='/admin/dashboard'>Dashboard</Link>
                    </li>
                    <li className='breadcrumb-item active'>Change Password</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-3 col-md-4'>
                <SideMenu />
              </div>
              <div className='col-xl-9 col-md-8'>
                <div className='card'>
                  <div className='card-header'>
                    <h5 className='card-title'>Change Password</h5>
                  </div>
                  <div className='card-body'>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      onSubmit={async (values, { setSubmitting }) => {
                        const postdata = {
                          current_password: values.currentPass,
                          new_password: values.newPass,
                        };
                        await ChangePasswordService(postdata)
                          .then((res) => {
                            SuccessToast(res?.data?.message || 'Merchnat Created.');
                            setSubmitting(false);
                            setTimeout(() => {
                              localStorage.removeItem('accessToken');
                              localStorage.removeItem('userData');
                              window.location.assign('/admin/dashoard');
                            }, 3000);
                          })
                          .catch((err) => {
                            if (typeof err.response.data.message !== 'undefined') {
                              ErrorToast(err.response.data.message || 'Server Error!!');
                            } else {
                              ErrorToast(err?.message || 'Server Error!!');
                            }
                            setSubmitting(false);
                          });
                      }}
                    >
                      {(formik) => {
                        const { errors, touched, isSubmitting, values } = formik;
                        return (
                          <Form>
                            <div className='row form-group'>
                              <label htmlFor='current_password' className='col-sm-3 col-form-label input-label'>
                                Current Password <span className='text-danger'>*</span>
                              </label>
                              <div className='col-sm-9 eye-pos'>
                                <Field
                                  type={isShowPasswordCurrent ? 'text' : 'password'}
                                  className={`form-control ${
                                    errors.currentPass && touched.currentPass ? 'input-error' : null
                                  }`}
                                  id='current_password'
                                  placeholder='Enter current password'
                                  name='currentPass'
                                />
                                <span
                                  onClick={() => setIsShowPasswordCurrent(!isShowPasswordCurrent)}
                                  className={`fas ${
                                    isShowPasswordCurrent ? 'fa-eye-slash' : 'fa-eye'
                                  } toggle-password-us`}
                                />
                                <ErrorMessage name='currentPass' component='span' className='error' />
                              </div>
                            </div>
                            <div className='row form-group'>
                              <label htmlFor='new_password' className='col-sm-3 col-form-label input-label'>
                                New Password <span className='text-danger'>*</span>
                              </label>
                              <div className='col-sm-9 eye-pos'>
                                <Field
                                  type={isShowPasswordNew ? 'text' : 'password'}
                                  id='new_password'
                                  className={`form-control ${errors.newPass && touched.newPass ? 'input-error' : null}`}
                                  placeholder='Enter new password'
                                  name='newPass'
                                />
                                <span
                                  onClick={() => setIsShowPasswordNew(!isShowPasswordNew)}
                                  className={`fas ${isShowPasswordNew ? 'fa-eye-slash' : 'fa-eye'} toggle-password-us`}
                                />
                                <ErrorMessage name='newPass' component='span' className='error' />
                                <PasswordStrengthBar password={values.newPass} className='p-h-6 my-2' />
                              </div>
                            </div>
                            <div className='row form-group'>
                              <label htmlFor='confirm_password' className='col-sm-3 col-form-label input-label'>
                                Confirm New Password <span className='text-danger'>*</span>
                              </label>
                              <div className='col-sm-9 eye-pos'>
                                <div className='mb-3'>
                                  <Field
                                    type={isShowPasswordConfirm ? 'text' : 'password'}
                                    className={`form-control ${
                                      errors.conformNewPass && touched.conformNewPass ? 'input-error' : null
                                    }`}
                                    id='confirm_password'
                                    placeholder='Enter new password again'
                                    name='conformNewPass'
                                  />
                                  <span
                                    onClick={() => setIsShowPasswordConfirm(!isShowPasswordConfirm)}
                                    className={`fas ${
                                      isShowPasswordConfirm ? 'fa-eye-slash' : 'fa-eye'
                                    } toggle-password-us`}
                                  />
                                  <ErrorMessage name='conformNewPass' component='span' className='error' />
                                </div>
                                <h5>Password requirements:</h5>
                                <p className='mb-2'>Ensure that these requirements are met:</p>
                                <ul className='list-unstyled small'>
                                  <li>Minimum 8 characters long - the more, the better</li>
                                  <li>At least one lowercase character</li>
                                  <li>At least one uppercase character</li>
                                  <li>At least one number, symbol</li>
                                </ul>
                              </div>
                            </div>
                            <div className='text-end'>
                              <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                                Save Changes&nbsp;&nbsp;
                                {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='container-fluid absolute pb-4'>
              <footer className='text-center text-lg-start'>
                <div className='text-center'>Powered by Alsaffron System.</div>
              </footer>
            </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default ChangePassword;
