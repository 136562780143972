import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import SelectBox from '../../../../../../Components/SelectBox';

const DemoRequestSearchBar = ({ refetch }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (values) {
      refetch();
    }
  }, [refetch, values]);

  return (
    <div className='row mb-3 align-item-right'>
      <div className='col-sm-3 text-right'>
        <label className='text-white'>Is Completed?</label>
        <Field
          component={SelectBox}
          name='is_complete'
          options={[
            {
              label: 'Both',
              value: null,
            },
            {
              label: 'Completed',
              value: true,
            },
            {
              label: 'Pending',
              value: false,
            },
          ]}
        />
      </div>
      {/* <div className='col-md-3 col-xl-3 col-lg-3'>
        <label className='text-white d-block'>Search</label>
        <input
          className='input-customize'
          type='text'
          name='search'
          value={values?.search}
          onChange={(e) => {
            const query = e.target.value;
            setFieldValue('search', query);
          }}
          placeholder='search...'
        />
      </div> */}
    </div>
  );
};

export default DemoRequestSearchBar;
