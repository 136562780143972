import Api from '../Utils/Axios';

export const getYearList = async () => {
  const response = await Api.get('api/general/get-year-range');
  return response.data;
};

export const getServices = async () => {
  const response = await Api.get('api/general/get-all-services');
  return response.data;
};

export const getCountries = async () => {
  const response = await Api.get('api/general/get-all-countries');
  return response.data;
};

export const getRegions = async () => {
  const response = await Api.get('api/general/get-all-region');
  return response.data;
};
