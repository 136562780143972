import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { Helmet } from 'react-helmet-async';
import { EditUserService, GetUserByIdService } from '../../../../Services/Api/user';
import { GetRoleListService } from '../../../../Services/Api/role';
import { ErrorToast, SuccessToast } from '../../../../Utils/SweetAlert';
import PhoneInput from 'react-phone-number-input';
import { UserValidationSchema } from '../../constant';

const EditUser = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || '';

  const [user, setUser] = useState();
  const [roleList, setRoleList] = useState([]);

  const navigate = useNavigate();

  const basicInitialValues = {
    name: user?.name || '',
    contact_number: user?.mobile || '',
    email: user?.email || '',
    role: roleList.find((option) => user.user_role.role_name === option.label) || {},
    // website: "http://www.example.com/",
  };

  const getUserById = async () => {
    await GetUserByIdService(id)
      .then((res) => {
        setUser(res?.data?.data?.user);
      })
      .catch(() => {
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  const getRoles = async () => {
    await GetRoleListService().then((res) => {
      if (res?.data?.data?.roles.length > 0) {
        let rolearr = res?.data?.data?.roles;
        rolearr = rolearr.map((value) => {
          return {
            label: value.role_name,
            value: value._id,
          };
        });
        setRoleList(rolearr);
      } else {
        // ErrorToast("No Results.");
        setRoleList([]);
      }
    });
  };

  useEffect(() => {
    getUserById();
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Parato | Admin Edit User</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row'>
              <div className='col'>
                <h3 className='page-title'>Edit User</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/dashboard'>Dashboard</Link>
                  </li>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/users'>Users</Link>
                  </li>
                  <li className='breadcrumb-item active'>Edit User</li>
                </ul>
              </div>
              <div className='col-auto'>
                <Link to='/admin/users' className='btn btn-primary me-1'>
                  <i className='fas fa-angle-double-left' /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className='card-title'>Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={basicInitialValues}
                    validationSchema={UserValidationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      const postObj = {
                        name: values?.name,
                        contact_number: values?.contact_number,
                        role_id: values?.role?.value,
                      };
                      await EditUserService(id, postObj)
                        .then((res) => {
                          SuccessToast(res?.data?.message || 'User updated.');
                          setSubmitting(false);
                          navigate('/admin/users');
                        })
                        .catch((err) => {
                          if (typeof err.response.data.message !== 'undefined') {
                            ErrorToast(err.response.data.message || 'Server Error!!');
                          } else {
                            ErrorToast(err?.message || 'Server Error!!');
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({ errors, touched, values, handleChange, isSubmitting, setFieldValue }) => {
                      return (
                        <Form>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='form-group'>
                                <label>
                                  Name <span className='text-danger'>*</span>
                                </label>
                                <Field
                                  type='text'
                                  className={`form-control ${errors.name && touched.name ? 'input-error' : null}`}
                                  name='name'
                                  onChange={handleChange}
                                />
                                <ErrorMessage name='name' component='span' className='error ' />
                              </div>
                              <div className='form-group'>
                                <label>
                                  Email <span className='text-danger'>*</span>
                                </label>
                                <Field
                                  type='email'
                                  className={`form-control ${errors.email && touched.email ? 'input-error' : null}`}
                                  id='email'
                                  name='email'
                                  onChange={handleChange}
                                  placeholder='Enter email'
                                  value={values.email}
                                  disabled
                                />
                                <ErrorMessage name='email' component='span' className='error' />
                              </div>
                            </div>

                            <div className='col-md-6'>
                              <div className='form-group remove-inner-input'>
                                <label>
                                  Contact Number <span className='text-danger'>*</span>
                                </label>
                                <PhoneInput
                                  defaultCountry='AE'
                                  international
                                  countryCallingCodeEditable={false}
                                  type='text'
                                  className={`form-control ${
                                    errors.contact_number && touched.contact_number ? 'input-error' : null
                                  }`}
                                  id='contact_number'
                                  name='contact_number'
                                  onChange={(newVal) => {
                                    setFieldValue('contact_number', newVal || '');
                                  }}
                                  value={values.contact_number}
                                />
                                <ErrorMessage name='contact_number' component='span' className='error' />
                              </div>

                              <div className='form-group'>
                                <label>
                                  Role <span className='text-danger'>*</span>
                                </label>
                                <Select
                                  options={roleList}
                                  onChange={(e) => {
                                    setFieldValue('role', e);
                                  }}
                                  name='role'
                                  value={values.role}
                                  placeholder='Select role'
                                />
                                <ErrorMessage name='role' component='span' className='error' />
                              </div>

                              {/* <div className="form-group">
                                <label>Website</label>

                                <Field
                                  type="text"
                                  className={
                                    `form-control ${errors.website && touched.website ? "input-error" : null}`
                                  }
                                  placeholder="website"
                                  name="website"
                                />
                                <ErrorMessage name="website" component="span" className="error" />
                              </div> */}
                            </div>
                          </div>
                          <div className='text-end mt-4'>
                            <button type='submit' className='btn btn-primary btnstl' disabled={isSubmitting}>
                              Edit User
                              {isSubmitting && <i className='fas fa-spinner fa-spin'></i>}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            {/* <div className='container-fluid absolute mb-4'>
              <footer className='text-center text-lg-start'>
                <div className='text-center'>Powered by Alsaffron System.</div>
              </footer>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
