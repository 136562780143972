import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BsDot } from 'react-icons/bs';
import { Skeleton } from '@mui/material';

import './style.css';
import { GetSingleUser } from '../../../../Api/user';
import { ErrorToast } from '../../../../Utils/SweetAlert';
import NoProfile from '../../../../assets/Images/no-profile.png';

const ViewUser = () => {
  let { state } = useLocation();

  const { data: singleUserData, isLoading: singleUserDataLoading } = useQuery({
    queryKey: ['get-single-user-list'],
    queryFn: () =>
      GetSingleUser({
        Id: state?.id,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  return (
    <>
      <Helmet>
        <title>Parato | Admin User Details</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>User Details</h3>
                <ul className='breadcrumb '>
                  <li className='breadcrumb-item active' aria-current='page'>
                    <Link to='/admin/dashboard' className='text-info text-decoration-underline'>
                      Dashboard
                    </Link>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    <Link to='/admin/user-listing' className='text-info text-decoration-underline'>
                      Users-List
                    </Link>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    User-Detais
                  </li>
                </ul>
              </div>
              <div className='col-auto'>
                <Link to='/admin/user-listing' className='btn btn-them-gray me-1'>
                  <i className='fas fa-angle-double-left' /> Back
                </Link>
              </div>

              {!singleUserDataLoading && singleUserData?.data === undefined && (
                <div className=''>
                  <p className='no-data bg-pareto-theme'>No Data</p>
                </div>
              )}

              {singleUserDataLoading && (
                <div className=''>
                  <p className='no-data bg-pareto-theme'>Loading...</p>
                </div>
              )}

              {!singleUserDataLoading && singleUserData?.data !== undefined && (
                <div className='bg-pareto-theme mt-3 ms-2 pb-3'>
                  <div className='row my-5 align-items-center'>
                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='circular' width={75} height={75} />
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        {singleUserData?.data?.profile_picture_url !== null ? (
                          <img src={singleUserData?.data?.profile_picture_url} alt='profile-picture' />
                        ) : (
                          <img src={NoProfile} alt='no-picture' className='no-profile-manage' />
                        )}
                        <div className='ms-2'>
                          <label className='d-block modal-label'>Full Name</label>
                          <span className='d-block input-label'>
                            {`${singleUserData?.data?.first_name} ${singleUserData?.data?.last_name}`}
                          </span>
                        </div>
                      </div>
                    )}

                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block'>
                        <label className='d-block modal-label'>Company Name</label>
                        <span className='d-block input-label'>{singleUserData?.data?.company_name}</span>
                      </div>
                    )}

                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block'>
                        <label className='d-block modal-label'>Job Title</label>
                        <span className='d-block input-label'>{singleUserData?.data?.job_title}</span>
                      </div>
                    )}

                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block'>
                        <label className='d-block modal-label'>Contact Number</label>
                        <span className='d-block input-label'>{singleUserData?.data?.mobile_no}</span>
                      </div>
                    )}

                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block'>
                        <label className='d-block modal-label'>E-mail</label>
                        <span className='d-block input-label'>{singleUserData?.data?.email}</span>
                      </div>
                    )}
                  </div>

                  <div className='row mb-5'>
                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center ms-2'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block ms-2'>
                        <label className='d-block modal-label font-weight-bold'>Interested Countries</label>
                        <span className='d-block input-label'>
                          <ul className='ul-group-list'>
                            {singleUserData?.data?.interested_countries.map((item) => {
                              return (
                                <div key={item?.id}>
                                  <li className=''>
                                    <BsDot size='22' />
                                    {item?.name || '-'}
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </span>
                      </div>
                    )}

                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block'>
                        <label className='d-block modal-label'>Interested Regions</label>
                        <span className='d-block input-label'>
                          <ul>
                            {singleUserData?.data?.interested_region.map((item) => {
                              return (
                                <div key={item?.id}>
                                  <li>
                                    <BsDot size='22' />
                                    {item?.name || '-'}
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </span>
                      </div>
                    )}

                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block'>
                        <label className='d-block modal-label'>Interested Indices</label>
                        <span className='d-block input-label'>
                          <ul>
                            {singleUserData?.data?.interested_indices.map((item) => {
                              return (
                                <div key={item?.id}>
                                  <li>
                                    <BsDot size='22' />
                                    {item?.name || '-'}
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </span>
                      </div>
                    )}
                  </div>

                  <div className='row '>
                    {singleUserDataLoading ? (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-flex align-items-center'>
                        <Skeleton variant='rectangular' width={190} height={60} className='ms-2' />
                      </div>
                    ) : (
                      <div className='col-md-3 col-xl-3 col-lg-3 d-block'>
                        <label className='d-block modal-label'>Subscribed User ? </label>
                        {singleUserData?.data?.is_paid === true ? (
                          <span className='badge badge-success'>Subscribed</span>
                        ) : (
                          <span className='badge badge-danger'>Not Subscribed</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUser;
