import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ImCancelCircle } from 'react-icons/im';
import './style.css';

const DetailModal = ({ title, children, onClose, show, size }) => {
  return (
    <Modal centered show={show} onHide={onClose} size={size} className='detail-modal-containe'>
      <Modal.Header className='modal-header-box'>
        <h5 className='modal-title'> {title} </h5>
        <button type='button' className='detail-modal-close-btn bg-none border-none' onClick={onClose}>
          <ImCancelCircle size={25} />
        </button>
      </Modal.Header>

      <Modal.Body className='detail-modal-body'>{children}</Modal.Body>
    </Modal>
  );
};

export default DetailModal;
