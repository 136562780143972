import Api from '../Utils/Axios';

export const GetSubscriptionRequestList = async (data) => {
  const response = await Api.post(`admin/subscription-request/list`, data);
  return response.data;
};

export const PostMarkAsFreeOrPaid = async (data) => {
  const response = await Api.post('admin/subscription-request/status', data);
  return response.data;
};

export const PostMarkAsActiveInactive = async (data) => {
  const response = await Api.post('admin/users/status', data);
  return response.data;
};
