import Api from '../../Utils/Axios';

export const GetRoleListService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/admin/role'));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetResourceService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/admin/resource'));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddRoleService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/admin/role', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditRoleService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.put('/admin/role', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetRoleListById = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get(`/admin/role?id=${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteRoleById = async (id) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.delete('/admin/role', { data: { id: id } }));
    } catch (error) {
      reject(error);
    }
  });
};
