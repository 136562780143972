/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import './style.css';

import {
  clearAllNotificationListSearvice,
  getAllNotification,
  getUnreadNotification,
  // getUnreadNotificationCount,
} from '../../Services/Api/notification';
// import { getProfile } from '../../Services/Api/auth';

const AdminHeader = (props) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [userData] = useState({});
  const path = useLocation();
  const navigate = useNavigate();

  const getNotificationCount = () => {
    // getUnreadNotificationCount().then((res) => {
    //   setNotificationCount(res.data.data.unread_count);
    // });
  };
  const getNotificationList = () => {
    getUnreadNotification()
      .then((res) => {
        setNotificationList(res.data.data.notifications);
      })
      .catch(() => {
        setNotificationList([]);
      });
  };
  const clearAllNotification = () => {
    clearAllNotificationListSearvice().then(() => {
      setNotificationCount(0);
    });
  };
  // const GetProfile = () => {
  //   getProfile()
  //     .then((res) => {
  //       setUserData(res.data.data);
  //     })
  //     .catch(() => {
  //       // if (typeof err.response.data.message !== "undefined") {
  //       //   ErrorToast(err.response.data.message || "Server Error!!");
  //       // } else {
  //       //   ErrorToast(err?.message || "Server Error!!");
  //       // }
  //     });
  // };
  const getNotificationListFromCurrent = (id) => {
    getAllNotification(id)
      .then(() => {
        getNotificationCount();
      })
      .catch(() => {});
  };

  return (
    <div>
      <div className='header-left header-content header-left-one'>
        <Link to='/admin/dashboard' className='logo'>
          <img src='/images/logo.png' className='header-logo' alt='user.png' />
          {/* <h3>Pareto</h3> */}
        </Link>
        <Link to='/admin/dashboard' className='logo logo-small'>
          <img src='/images/small_logo.svg' className='header-small-logo' alt='user.png' />
          {/* <h3>P</h3> */}
        </Link>
      </div>
      <Link to='#' id='toggle_btn' className='header-toggle-btn'>
        <i className='fas fa-bars'></i>
      </Link>
      <Link to='#' className='mobile_btn' id='mobile_btn' onClick={() => props.onClick(!props.showSideBar)}>
        <i className='fas fa-bars'></i>
      </Link>
      {/* <ul className='nav nav-tabs user-menu'>
        <li className='nav-item dropdown'>
          <Link
            to='#'
            className='dropdown-toggle nav-link'
            data-bs-toggle='dropdown'
            onClick={() => getNotificationList()}
          >
            <i className='fa fa-bell'></i>
            <span className={notificationCount > 0 ? 'badge rounded-pill ripple' : 'badge rounded-pill'}>
              {notificationCount}
            </span>
          </Link>
          <div className='dropdown-menu notifications'>
            <div className='topnav-dropdown-header'>
              <span className='notification-title'>Notifications</span>
              <Link to='#' onClick={() => clearAllNotification()} className='clear-noti'>
                Clear All
              </Link>
            </div>
            <div className='noti-content'>
              <ul className='notification-list'>
                {notificationList.length > 0 &&
                  notificationList.map((notification) => (
                    <li key={notification._id} className='notification-message'>
                      <span
                        onClick={() => {
                          if (path.pathname === '/admin/notifications') {
                            getNotificationListFromCurrent(notification._id);
                          } else {
                            getNotificationListFromCurrent(notification._id);
                            navigate('/admin/notifications', { state: { id: notification._id } });
                          }
                        }}
                      >
                        <div className="media d-flex">
                        <div className='row px-2'>
                          <div className='col-2'>
                            <span className="avatar avatar-sm">
                              <img className="avatar-img rounded-circle" src={notification?.user?.profile_picture || "/inner-assets/img/profiles/default_image.jpg"} alt="Profile pic" />
                            </span>
                        </div>
                        <div className='col-10'>
                        <div className="media-body">
                          <p className="noti-details">
                            {notification?.message}
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">{moment(notification?.createdAt).fromNow(true)} ago</span>
                          </p>
                        </div>
                        </div>
                        </div>
                      </div>
                        <div className='d-flex my-2'>
                          <div className='left-img header-notification'>
                            <img
                              className='avatar-img rounded-circle'
                              src={
                                notification?.user?.profile_picture || '/inner-assets/img/profiles/default_image.jpg'
                              }
                              alt='Profile pic'
                            />
                          </div>
                          <div className='right-content'>
                            <p className='noti-details'>{notification?.message}</p>
                            <p className='noti-time'>
                              <span className='notification-time'>
                                {moment(notification?.createdAt).fromNow(true)} ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className='topnav-dropdown-footer'>
              <Link to={{ pathname: '/admin/notifications' }}>View all Notifications</Link>
            </div>
          </div>
        </li>
        <li className='nav-item main-drop'>
          <Link to='/admin/profile' className='nav-link'>
            <span className='user-img'>
              <img src={userData.profile_picture || '/inner-assets/img/profiles/default_image.jpg'} alt='imge' />
              <span className='status online'></span>
            </span>
            <span>{userData.name}</span>
          </Link>
        </li>
      </ul> */}
    </div>
  );
};

export default AdminHeader;
