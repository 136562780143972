import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import SelectBox from '../../../../../../Components/SelectBox';

const UserTableSearchBar = ({ refetch, handleExportExcel, loading }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values) {
      refetch();
    }
  }, [refetch, values]);

  return (
    <div className='row mb-3 align-items-end'>
      <div className='col-md-3 col-xl-3 col-lg-3'>
        <label className='text-white d-block'>Is Paid?</label>
        <Field
          component={SelectBox}
          name='is_paid'
          options={[
            {
              label: 'Both',
              value: null,
            },
            {
              label: 'Paid User',
              value: true,
            },
            {
              label: 'Free User',
              value: false,
            },
          ]}
        />
      </div>
      <div className='col-md-3 col-xl-3 col-lg-3'>
        <label className='text-white d-block'>Search</label>
        <input
          className='input-customize'
          type='text'
          name='search'
          value={values?.search}
          onChange={(e) => {
            const query = e.target.value;
            setFieldValue('search', query);
          }}
          placeholder='search here....'
        />
      </div>
      <div className='col-md-3 col-xl-3 col-lg-3'>
        <button
          onClick={handleExportExcel}
          type='button'
          className='btn btn-them-gray me-1'
          disabled={loading} // Disable button while loading
        >
          {loading ? (
            <>
              <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              Loading ...
            </>
          ) : (
            <>
              <i className='fas fa-download' /> Export Excel
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default UserTableSearchBar;
