import { useMutation } from '@tanstack/react-query';
import { postLogin } from '../../../../Api/auth';

export const useMutateLoginHook = () =>
  useMutation({
    mutationFn: (data) => postLogin(data),
  });

// export const useMutateVerifyOtpHook = () =>
//   useMutation({
//     mutationFn: (data) => postVerifyOtp(data),
//   });
