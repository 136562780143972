import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AdminLayout } from '../Components/Layout';
import PrivateRoute from './PrivateRoute';
import { useEffect } from 'react';
import { ChangePassword, Settings, CRPenaltyRequest } from '../Components/Admin/settings';
import ComponentPrint from '../Components/Admin/Common/ComponentPrint';
import { useState } from 'react';
import Dashboard from '../Pages/Dashboard';
import Profile from '../Pages/Profile';
import Notification from '../Pages/Notification';
import { AddUser, EditUser, UserList } from '../Pages/UserManagement';
import { AddRole, EditRole, RoleList } from '../Pages/RoleManagement';
import PermissionAlert from '../Pages/PermissionAlert';
import { InsightsList, AddInsight, EditInsight } from '../Pages/Insights';
import { SubscriptionRequests } from '../Pages/SubscriptionRequests';
import ContactUs from '../Pages/ContactUs';
import { UserListing } from '../Pages/User';
import ViewUser from '../Pages/User/components/ViewUser';
import { DemoRequestList } from '../Pages/DemoRequests';

const Logout = () => {
  const navigation = useNavigate();
  useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('adminPermission');
    navigation('/');
  }, [navigation]);
};
const AdminRoutesPermission = () => {
  const location = useLocation();
  const route = location.pathname.slice(7);
  const [loading, setLoading] = useState(true);
  const permission = JSON.parse(localStorage.getItem('adminPermission'));

  useEffect(() => {
    setLoading(false);
  }, [permission]);

  return (
    <React.Fragment>
      <AdminLayout />
      {!loading && (
        <Routes>
          <Route exact path='/' element={<PrivateRoute Component={Dashboard} />} />
          <Route exact path='/dashboard' element={<PrivateRoute Component={Dashboard} />} />

          {permission?.indexOf('user_management') !== -1 && route === 'users' ? (
            <Route exact path='/users' element={<PrivateRoute Component={UserList} />} />
          ) : (
            <Route exact path='/users' element={<PermissionAlert />} />
          )}

          {permission?.indexOf('user_management') !== -1 && route === 'add-user' ? (
            <Route exact path='/add-user' element={<PrivateRoute Component={AddUser} />} />
          ) : (
            <Route exact path='/add-user' element={<PermissionAlert />} />
          )}

          {permission?.indexOf('user_management') !== -1 && route === 'edit-user' ? (
            <Route exact path='/edit-user' element={<PrivateRoute Component={EditUser} />} />
          ) : (
            <Route exact path='/edit-user' element={<PermissionAlert />} />
          )}

          {permission?.indexOf('role_management') !== -1 && route === 'role' ? (
            <Route exact path='/role' element={<PrivateRoute Component={RoleList} />} />
          ) : (
            <Route exact path='/role' element={<PermissionAlert />} />
          )}

          {permission?.indexOf('role_management') !== -1 && route === 'add-role-management' ? (
            <Route exact path='/add-role-management' element={<PrivateRoute Component={AddRole} />} />
          ) : (
            <Route exact path='/add-role-management' element={<PermissionAlert />} />
          )}

          {permission?.indexOf('role_management') !== -1 && route === 'edit-role-permission' ? (
            <Route exact path='/edit-role-permission' element={<PrivateRoute Component={EditRole} />} />
          ) : (
            <Route exact path='/edit-role-permission' element={<PermissionAlert />} />
          )}

          {permission?.indexOf('customer_bookings') !== -1 && route === 'view-booking' ? (
            <Route exact path='/view-booking' element={<PrivateRoute Component={ComponentPrint} />} />
          ) : (
            <Route exact path='/view-booking' element={<PermissionAlert />} />
          )}

          <Route exact path='/profile' element={<PrivateRoute Component={Profile} />} />
          <Route exact path='/notifications' element={<PrivateRoute Component={Notification} />} />
          <Route exact path='/setting' element={<PrivateRoute Component={Settings} />} />
          <Route exact path='/setting/change-password' element={<PrivateRoute Component={ChangePassword} />} />

          <Route exact path='/insight' element={<PrivateRoute Component={InsightsList} />} />
          <Route exact path='/add-insight' element={<PrivateRoute Component={AddInsight} />} />
          <Route exact path='/edit-insight' element={<PrivateRoute Component={EditInsight} />} />

          <Route exact path='subscription-requests' element={<PrivateRoute Component={SubscriptionRequests} />} />
          <Route exact path='user-listing' element={<PrivateRoute Component={UserListing} />} />
          <Route exact path='view-user' element={<PrivateRoute Component={ViewUser} />} />

          <Route exact path='demo-requests' element={<PrivateRoute Component={DemoRequestList} />} />

          {permission?.indexOf('cr_penalty_setting') !== -1 && route === 'credit-request-penalty' ? (
            <Route exact path='/credit-request-penalty' element={<PrivateRoute Component={CRPenaltyRequest} />} />
          ) : (
            <Route exact path='/credit-request-penalty' element={<PermissionAlert />} />
          )}

          <Route exact path='/contact-us' element={<PrivateRoute Component={ContactUs} />} />

          <Route exact path='/logout' element={<Logout />} />
        </Routes>
      )}
    </React.Fragment>
  );
};
export default AdminRoutesPermission;
