import React from 'react';
import DetailModal from '../../../../Components/DetailModal';

const ContactUsDetail = ({ onClose, details }) => {
  const timeZone = details?.schedule_time;
  const date = new Date(timeZone);
  let meetingTime = date.toLocaleString(date);

  return (
    <DetailModal centered show size='md' onClose={onClose} title='Contact Us Details'>
      <div className='row'>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Full Name</label>
          <span className='d-block modal-value'>
            {`${details?.user_id?.first_name} ${details?.user_id?.last_name}` || '-'}
          </span>
        </div>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Company Name</label>
          <span className='d-block modal-value'>{details?.user_id?.company_name || '-'}</span>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Email</label>
          <span className='d-block modal-value'>{details?.user_id?.email || '-'}</span>
        </div>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Communication Type</label>
          <span className='d-block modal-value'>{details?.communication_type || '-'}</span>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-12 col-xl-12 col-lg-12 d-block'>
          <label className='d-block modal-label'>Schedule Time</label>
          <span className='d-block modal-value'>{`${meetingTime} (${details?.timezone})` || '-'}</span>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-12 col-xl-12 col-lg-12 d-block'>
          <label className='d-block modal-label'>Message</label>
          <span className='d-block modal-value'>{details?.message || '-'}</span>
        </div>
      </div>
    </DetailModal>
  );
};

export default ContactUsDetail;
