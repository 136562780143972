import React from 'react';
import DetailModal from '../../../../Components/DetailModal';

const RequestDemoDetails = ({ onClose, details }) => {
  const timeZone = details?.schedule_time;
  const date = new Date(timeZone);
  let meetingTime = date.toLocaleString(date);

  return (
    <DetailModal centered show size='lg' onClose={onClose} title='Demo Request Details'>
      <div className='row'>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Full Name</label>
          <span className='d-block modal-value'>
            {`${details?.user_id?.first_name} ${details?.user_id?.last_name}` || '-'}
          </span>
        </div>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Company Name</label>
          <span className='d-block modal-value'>{details?.user_id?.company_name || '-'}</span>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Email</label>
          <span className='d-block modal-value'>{details?.user_id?.email || '-'}</span>
        </div>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Schedule Time</label>
          <span className='d-block modal-value'>{`${meetingTime} (${details?.timezone})` || '-'}</span>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Preferred Meet Service Type</label>
          <div className='modal-view-metting'>
            <img src={`/images/${details?.preferred_meet_service}.png`} className='metting-icons' alt='user.png' />
            &nbsp;&nbsp;&nbsp;&nbsp;{details?.preferred_meet_service || '-'}
          </div>
        </div>
        <div className='col-md-6 col-xl-6 col-lg-6 d-block'>
          <label className='d-block modal-label'>Explore Features</label>
          <span className='d-block modal-value'>{details?.explore_feature || '-'}</span>
        </div>
      </div>
    </DetailModal>
  );
};

export default RequestDemoDetails;
