/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { adminGetUserPermissionService } from '../../Services/Api/user';
import { MdOutlineRequestQuote } from 'react-icons/md';
import { IoMdContact } from 'react-icons/io';
import { BiNews } from 'react-icons/bi';
import { LuLogOut } from 'react-icons/lu';
import { FaUsers } from 'react-icons/fa';
import { MdOutlineMissedVideoCall } from 'react-icons/md';
import './style.css';

const AdminSidebar = ({ setOpenSidebar }) => {
  const [permission, setPermission] = useState([]);
  const [role, setRole] = useState();

  const getPermission = async () => {
    await adminGetUserPermissionService()
      .then((res) => {
        const permissionArr = res?.data.data.permissions.role_meta;
        const resourceArr = permissionArr.map((value) => {
          return value.role_resource.resource_key;
        });
        setPermission(resourceArr);
        localStorage.setItem('adminPermission', JSON.stringify(resourceArr));
      })
      .catch((err) => {
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  useEffect(() => {
    const role = JSON.parse(localStorage.getItem('userData'));
    setRole(role?.role);
    if (role?.role === 'sub_admin') {
      getPermission();
    }
  }, []);

  return (
    <div
      className='sidebar'
      id='sidebar'
      style={setOpenSidebar === true ? { marginLeft: '0px' } : { marginLeft: '-225px' }}
    >
      <div className='sidebar-inner slimscroll'>
        <div id='sidebar-menu' className='sidebar-menu'>
          <ul>
            {/* <li className='menu-title'>
              <span>Main</span>
            </li> */}

            <li>
              <NavLink to='/admin/dashboard' className='text-nowrap d-flex'>
                <i data-feather='home' />
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='feather feather-home'
                >
                  <path d='M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z'></path>
                  <polyline points='9 22 9 12 15 12 15 22'></polyline>
                </svg>
                <span>Dashboard</span>
              </NavLink>
            </li>

            <li>
              <NavLink to='/admin/user-listing' className='text-nowrap d-flex'>
                <i data-feather='user' />
                <FaUsers className='icon-width-height' />
                <span>User</span>
              </NavLink>
            </li>

            <li>
              <NavLink to='/admin/insight' className='text-nowrap d-flex'>
                <i data-feather='user' />
                <BiNews className='icon-width-height' />
                <span>Insight</span>
              </NavLink>
            </li>

            <li>
              <NavLink to='/admin/contact-us' className='text-nowrap d-flex'>
                <i data-feather='user' />
                <IoMdContact className='icon-width-height' />
                <span>Contact Us</span>
              </NavLink>
            </li>

            <li>
              <NavLink to='/admin/subscription-requests' className='text-nowrap d-flex'>
                <i data-feather='user' />
                <MdOutlineRequestQuote className='icon-width-height' />
                <span>Subscription Requests</span>
              </NavLink>
            </li>

            <li>
              <NavLink to='/admin/demo-requests' className='text-nowrap d-flex'>
                <i data-feather='user' />
                <MdOutlineMissedVideoCall className='icon-width-height' />
                <span>Demo Requests</span>
              </NavLink>
            </li>

            <li>
              <NavLink to='/admin/logout' className='text-nowrap d-flex'>
                <i data-feather='user' />
                <LuLogOut className='icon-width-height' />
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AdminSidebar;
