import Api from '../../Utils/Axios';

export const LoginService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/admin/login', data));
    } catch (error) {
      reject(error);
    }
  });
};
export const ForgotPasswordService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/admin/auth/forgot_password', data));
    } catch (error) {
      reject(error);
    }
  });
};
export const ResetPasswordService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/admin/auth/reset_password', data));
    } catch (error) {
      reject(error);
    }
  });
};
export const getProfile = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/admin/get_profile', data));
    } catch (error) {
      reject(error);
    }
  });
};
export const updateProfile = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/auth/update_profile', data));
    } catch (error) {
      reject(error);
    }
  });
};

export const AdminGetWalletBalanceService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/master/dashboard'));
    } catch (error) {
      reject(error);
    }
  });
};

export const ChangePasswordService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post('/admin/change_password', data));
    } catch (error) {
      reject(error);
    }
  });
};
