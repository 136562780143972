import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Pagination } from '../../Utils';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ErrorToast } from '../../Utils/SweetAlert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GetContactUsList } from '../../Api/contactus';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import ContactUsDetail from './components/ContactUsDetail';
import './style.css';
// import TableSearchBar from './components/TableSearchBar';
// import { Formik } from 'formik';
// import { contactUsSearchBarInitialValues } from './constant';

const ContactUs = () => {
  const formRef = useRef();
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const {
    isFetching: isContactUsLoading,
    data: contactList,
    refetch,
  } = useQuery({
    queryKey: ['get-contact-us-list', limit, page],
    queryFn: () =>
      GetContactUsList({
        limit: limit,
        offset: page,
        search: formRef?.current?.values?.search || '',
        sortMode: 'desc',
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    refetch();
  };

  const handleLimitChange = async (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    refetch();
  };

  const handleDetailModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <Helmet>
        <title>Parato | Admin Contact Us</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>Contact Us</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/dashboard' className='text-info text-decoration-underline'>
                      Dashboard
                    </Link>
                  </li>
                  <li className='breadcrumb-item'>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <Formik initialValues={contactUsSearchBarInitialValues} innerRef={formRef}>
            {() => {
              return (
                <form>
                  <TableSearchBar refetch={refetch} />
                </form>
              );
            }}
          </Formik> */}
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive custom-pagination'>
                    <table className='table table-center table<img<image-hover datatable table-manager'>
                      <thead className='thead-light'>
                        <tr>
                          <th className='text-center stickey-actions-heading'>Action</th>
                          <th>User</th>
                          <th>Email</th>
                          <th>Mobile-Number</th>
                          <th>Communication Type</th>
                          <th>Schedule Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isContactUsLoading && (
                          <tr>
                            <td align='center' colSpan={8}>
                              <div className='loader-wraper'>
                                <div className='spinner-border text-light' role='status' />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isContactUsLoading && contactList !== undefined && (
                          <>
                            {contactList?.data.map((item, index) => {
                              const timeZone = item?.schedule_time;
                              const date = new Date(timeZone);
                              let meetingTime = date.toLocaleString(date);

                              return (
                                <tr key={index} className=''>
                                  <td className='text-center stickey-actions-body'>
                                    <Tooltip
                                      title='View'
                                      className='btn btn-sm btn-white text-success me-2'
                                      onClick={() => {
                                        setDetail(item);
                                        handleDetailModal();
                                      }}
                                    >
                                      <IconButton>
                                        <MdOutlineRemoveRedEye className='text-success' />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                  <td className=''>
                                    {`${item?.user_id?.first_name}-${item?.user_id?.last_name}` || '-'}
                                  </td>
                                  <td className=''>{item?.user_id?.email || '-'}</td>
                                  <td className=''>{item?.user_id?.mobile_no || '-'}</td>
                                  <td className=''>{item?.communication_type || '-'}</td>
                                  <td className=''>{`${meetingTime} (${item?.timezone})` || '-'}</td>
                                  {open === true && <ContactUsDetail onClose={handleDetailModal} details={detail} />}
                                </tr>
                              );
                            })}
                          </>
                        )}

                        <Pagination
                          totalItem={contactList?.totalRecords || 0}
                          itemsPerPage={limit}
                          page={!contactList?.totalRecords || contactList?.totalRecords <= 0 ? 0 : page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className='content container-fluid absolute px-2'>
                <footer className='text-center text-lg-start'>
                  <div className='text-center pb-4'>Powered by Alsaffron System.</div>
                </footer>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
