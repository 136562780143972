/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import './style.css';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { GetDashboardCounters } from '../../Api/dashboard';
import { ErrorToast } from '../../Utils/SweetAlert';
import { MdOutlineRequestQuote } from 'react-icons/md';
import { MdOutlineMissedVideoCall } from 'react-icons/md';
import { IoMdContact } from 'react-icons/io';

const Dashboard = () => {
  const { data: counters, isFetching: countersLoading } = useQuery({
    queryKey: ['get-counters-dashboard'],
    queryFn: () => GetDashboardCounters(),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || 'Server error.');
    },
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Parato | Admin Dashboard</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='row'>
            <div className='col-xl-4 col-sm-4 col-12'>
              <Link to='/admin/user-listing' className='dashboard-card-text-link'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='dash-widget-header'>
                      <span className='dash-widget-icon bg-pareto-theme-black'>
                        <i className='fas fa-users' />
                      </span>
                      <div className='dash-count'>
                        <div className='dash-title dashboard-card-text-link'>Users</div>
                        <div className='dash-counts input-label'>
                          <td className='h4'>{countersLoading ? 'Loading...' : counters?.data?.user_count || 0}</td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-xl-4 col-sm-4 col-12'>
              <Link to='/admin/subscription-requests' className='dashboard-card-text-link'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='dash-widget-header'>
                      <span className='dash-widget-icon bg-pareto-theme-black'>
                        <i data-feather='user' />
                        <MdOutlineRequestQuote />
                      </span>
                      <div className='dash-count'>
                        <div className='dash-title dashboard-card-text-link'>Subscription Request</div>
                        <div className='dash-counts input-label'>
                          <td className='h4'>
                            {countersLoading ? 'Loading...' : counters?.data?.subscription_request_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-xl-4 col-sm-4 col-12'>
              <Link to='/admin/demo-requests' className='dashboard-card-text-link'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='dash-widget-header'>
                      <span className='dash-widget-icon bg-pareto-theme-black'>
                        <i data-feather='user' />
                        <MdOutlineMissedVideoCall />
                      </span>
                      <div className='dash-count'>
                        <div className='dash-title dashboard-card-text-link'>Demo Request</div>
                        <div className='dash-counts input-label'>
                          <td className='h4'>
                            {countersLoading ? 'Loading...' : counters?.data?.demo_request_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-xl-4 col-sm-4 col-12'>
              <Link to='/admin/contact-us' className='dashboard-card-text-link'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='dash-widget-header'>
                      <span className='dash-widget-icon bg-pareto-theme-black'>
                        <i data-feather='user' />
                        <IoMdContact />
                      </span>
                      <div className='dash-count'>
                        <div className='dash-title dashboard-card-text-link'>Contact Us</div>
                        <div className='dash-counts input-label'>
                          <td className='h4'>
                            {countersLoading ? 'Loading...' : counters?.data?.contact_us_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
