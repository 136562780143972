import { useMutation } from '@tanstack/react-query';
import { CreateInsight, DeleteInsight, UpdateInsight } from '../../../Api/insight';

export const useMutateCreateInsight = () =>
  useMutation({
    mutationFn: (data) => CreateInsight(data),
  });

export const useMutateEditInsight = () =>
  useMutation({
    mutationFn: (data) => UpdateInsight(data),
  });

export const useMutateDeleteInsight = () =>
  useMutation({
    mutationFn: (data) => DeleteInsight(data),
  });
