import { useQuery } from '@tanstack/react-query';
import { getCountries, getRegions, getServices } from '../Api/general';

export const useGetYearsHook = (startYear, endYear) => {
  var currentYear = endYear,
    years = [];
  // startYear = startYear || 2013;
  while (startYear <= currentYear) {
    const indYear = startYear++;
    years.push({ value: indYear, label: indYear });
  }
  const yearSorting = years.sort((a, b) => {
    if (a.value > b.label) {
      return -1;
    } else if (a.value < b.label) {
      return 1;
    }
    return 0;
  });
  return yearSorting;
};

export const useGetServicesHook = () =>
  useQuery({
    queryKey: ['register-services'],
    queryFn: getServices,
    select: (data) => [...data.data.map((item) => ({ ...item, value: item._id, label: item.name }))],
  });

export const useGetCountriesHook = () =>
  useQuery({
    queryKey: ['register-countries'],
    queryFn: getCountries,
    select: (data) => [...data.data.map((item) => ({ value: item._id, label: item.name, img: item.img }))],
  });
export const useGetRegionsHook = () =>
  useQuery({
    queryKey: ['register-regions'],
    queryFn: getRegions,
    select: (data) => [...data.data.map((item) => ({ value: item._id, label: item.name }))],
  });
