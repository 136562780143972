import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { DeleteRoleById, GetRoleListService } from '../../../../Services/Api/role';
import { Confirmation, ErrorToast, SuccessToast } from '../../../../Utils/SweetAlert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const RoleList = () => {
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getRoleList = async () => {
    setIsLoading(true);
    await GetRoleListService()
      .then((res) => {
        setIsLoading(false);
        setRoleList(res?.data?.data?.roles);
      })
      .catch(() => {
        setRoleList([]);
        setIsLoading(false);
        // if (typeof err.response.data.message !== "undefined") {
        //     ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //     ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };

  const handleDeleteRole = (deleteRoleId) => {
    Confirmation('Want to delete role?').then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteRoleById(deleteRoleId)
          .then((res) => {
            SuccessToast(res?.data?.message || 'Role Deleted.');
            getRoleList();
          })
          .catch((err) => {
            if (typeof err.response.data.message !== 'undefined') {
              ErrorToast(err.response.data.message || 'Server Error!!');
            } else {
              ErrorToast(err?.message || 'Server Error!!');
            }
          });
      }
    });
  };

  useEffect(() => {
    getRoleList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Parato | Admin Role Management</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='page-header'>
            <div className='row align-items-center'>
              <div className='col'>
                <h3 className='page-title'>Role &amp; Permissions</h3>
                <ul className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/admin/dashboard'>Dashboard</Link>
                  </li>
                  <li className='breadcrumb-item active'>Role &amp; Permissions</li>
                </ul>
              </div>
              <div className='col-auto'>
                <Link to='/admin/add-role-management' className='btn btn-primary me-1'>
                  <i className='fas fa-plus' /> Add Role &amp; Permissions
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='card card-table'>
                <div className='card-body'>
                  <div className='table-responsive'>
                    <table className='table table-center table-hover datatable'>
                      <thead className='thead-light'>
                        <tr>
                          <th>Role Name</th>
                          <th className='text-center'>Action</th>
                          <th>Permission</th>
                          {/* <th>Status</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {roleList.map((role, index) => (
                              <tr key={index}>
                                <td>{role?.role_name || '-'}</td>
                                <td className='text-center'>
                                  <Tooltip
                                    title='Edit'
                                    className='btn btn-sm btn-white text-success me-2'
                                    onClick={() =>
                                      navigate('/admin/edit-role-permission', {
                                        state: { id: role?._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon className='text-secondary' />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title='Delete'
                                    className='btn btn-sm btn-white text-danger me-2'
                                    onClick={() => handleDeleteRole(role?._id)}
                                  >
                                    <IconButton>
                                      <DeleteIcon className='text-danger' />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                                <td>
                                  {role?.role_meta.map((role, index) => (
                                    <div key={index}>{role.role_resource.resource_name}</div>
                                  ))}
                                </td>
                                {/* <td>
                            {role?.status === 1 &&
                                <span className="badge badge-pill bg-success-light">Active</span>
                            }
                            {role?.status === 0 &&
                                <span className="badge badge-pill bg-danger-light">Inactive</span>
                            }
                          </td> */}
                                {/* <td className="text-center">
                            <div className="dropdown dropdown-action">
                              <Link to="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v" /></Link>
                              <div className="dropdown-menu dropdown-menu-end  upPop-up">
                                <Link className="dropdown-item" 
                                to="/admin/edit-role-permission"
                                state={{ id: role?._id }}
                                ><i className="far fa-edit me-2" />Edit Role</Link>
                                <span className="dropdown-item"  style={{  cursor: "pointer"  }} onClick={()=> handleDeleteRole(role?._id)}><i className="far fa-trash-alt me-2" />Delete Role</span>
                              </div>
                            </div>
                          </td> */}
                              </tr>
                            ))}
                            {roleList.length === 0 && (
                              <tr>
                                <td align='left' colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className='content container-fluid absolute px-2'>
                <footer className='text-center text-lg-start'>
                  <div className='text-center pb-4'>Powered by Alsaffron System.</div>
                </footer>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleList;
