import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routings from './routes';
import { HelmetProvider } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import { getYearList } from './Api/general';
import { AppContext } from './context/appContext';
import { useGetCountriesHook, useGetServicesHook, useGetRegionsHook } from './Hooks/useGeneralHook';
function App() {
  const { isLoading: isYearLoading, data: years } = useQuery({
    queryKey: ['get-year-range'],
    queryFn: getYearList,
  });

  const { isLoading: isServicesLoading, data: services } = useGetServicesHook();

  const { isLoading: isCountriesLoading, data: countries } = useGetCountriesHook();

  const { isLoading: isRegionsLoading, data: regions } = useGetRegionsHook();

  return (
    <div className='App'>
      <AppContext.Provider
        value={{
          isYearLoading,
          years,
          isServicesLoading,
          services,
          isCountriesLoading,
          countries,
          isRegionsLoading,
          regions,
        }}
      >
        <HelmetProvider>
          <Router>
            <Routings />
          </Router>
        </HelmetProvider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
